import API from './Api';

class SecurityService extends API {
	login = (credential) => {
		return this.API.post(`${this.controller}/Login`, credential);
	};

	verifyStep1 = (phone) => {
		return this.API.post(`api/Security/VerifyStep1`, { phoneNumber: phone });
	};

	verifyStep2 = (phone, code) => {
		return this.API.post(`api/Security/VerifyStep2`, { phoneNumber: phone, verificationCode: code });
	};

	verifyCellphoneStep1 = (phone) => {
		return this.API.post(`api/Security/VerifyCellphoneStep1`, { phoneNumber: phone });
	};

	verifyCellphoneStep2 = (phone, code) => {
		return this.API.post(`api/Security/VerifyCellphoneStep2`, { phoneNumber: phone, verificationCode: code });
	};

	verifyEmailStep1 = (email) => {
		return this.API.post(`api/Security/VerifyEmailStep1`, { email });
	};

	verifyEmailStep2 = (email, code) => {
		return this.API.post(`api/Security/VerifyEmailStep2`, { email, verificationCode: code });
	};

	storeSession = (token) => {
		window.token = token;
		localStorage.setItem('appUser', JSON.stringify(token));
	};

	clearSession = () => {
		delete window.token;
		localStorage.clear();
	};
}

let securityService = new SecurityService('api/Security');

export default securityService;
