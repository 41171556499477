import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import * as esTranslations from "locales/es/translation.json";
import * as enTranslations from "locales/es/translation.json";

i18n
	.use(XHR)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		lng: 'es',
		fallbackLng: 'es',
		resources: {
			en: {
				translation: enTranslations.default,
			},
			es: {
				translation: esTranslations.default,
			}
		},
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		// keySeparator: true,
		// backend: {
		// 	loadPath: '/locales/{{lng}}/translation.json',
		// },
	});

export default i18n;
