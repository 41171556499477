import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectToken, selectSettings } from "../redux/UserRedux";

export const mapStateToProps = state => ({
    session: selectToken(state),
    settings: selectSettings(state),
});

export const mapDispatchToProps = {

};

export const hocComponentName = (WrappedComponent) => {
    const hocComponent = ({ ...props }) => <WrappedComponent {...props} />;

    hocComponent.propTypes = {
        /** session token data */
        session: PropTypes.object,
        settings: PropTypes.object
    };

    return hocComponent;
};

export default WrapperComponent => connect(mapStateToProps, mapDispatchToProps)(hocComponentName(WrapperComponent));