import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NotificationCreators, selectNotification, selectNotifications } from "../redux/NotificationRedux";

export const mapStateToProps = state => ({
    notifications: selectNotifications(state),
    notification: selectNotification(state),
});

export const mapDispatchToProps = dispatch => bindActionCreators({
    fetchNotifications: NotificationCreators.getNotificationsRequest,
    readNotification: NotificationCreators.readNotificationRequest,
    addNotification: NotificationCreators.addNotification,
    clearNotification: NotificationCreators.clearNotification,
}, dispatch);

export const hocWithNotifications = WrappedComponent => {
    const hoc = ({ ...props }) => <WrappedComponent {...props} />;

    hoc.propTypes = {
        notifications: PropTypes.array,
        readNotification: PropTypes.func,
        addNotification: PropTypes.func,
        clearNotification: PropTypes.func,
        notification: PropTypes.object
    };

    hoc.defaultProps = {
        notification: null,
        notifications: []
    };

    return hoc;
};

export default WithNotifications =>
    connect(mapStateToProps, mapDispatchToProps)(
        hocWithNotifications(WithNotifications)
    );