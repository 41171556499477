import { fork, all, call } from 'redux-saga/effects';
import userSagas from './userSagas';
import commonSagas from './commonSagas';
import notificationSagas from './notificationSagas';

export default function* rootSagas() {
	yield all([fork(userSagas), fork(notificationSagas), fork(commonSagas)]);
}

export const safeCall = (saga, ...args) =>
	call(function* () {
		try {
			return { err: false, result: yield call(saga, ...args) };
		} catch (err) {
			return { err: true, result: err };
		}
	});
