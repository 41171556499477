import { takeLatest, call, put } from 'redux-saga/effects';
import { CommonTypes, CommonCreators } from '../redux/CommonRedux';
import commonService from '../services/commonService';

export function* getAllBranches() {
	const response = yield call(commonService.getBranches);

	if (response.ok) {
		let items = response.data.data.map((i) => ({
			value: i.id,
			label: `${i.name}`,
			description: i.description,
		}));
		yield put(CommonCreators.setBranches(items));
	}
}

export function* getAppointmentStatuses() {
	const response = yield call(commonService.getAppointmentStatuses);

	if (response.ok) {
		let items = response.data.statuses;
		yield put(CommonCreators.setAppointmentStatuses(items));
	}
}

export function* getTenants() {
	const response = yield call(commonService.getTenants);

	if (response.ok) {
		
		yield put(CommonCreators.setTenants(response.data.data));
	}
}

function* userSagas() {
	yield takeLatest(CommonTypes.GET_BRANCHES, getAllBranches);
	yield takeLatest(CommonTypes.GET_APPOINTMENT_STATUSES, getAppointmentStatuses);
	yield takeLatest(CommonTypes.GET_TENANTS, getTenants);
}

export default userSagas;
