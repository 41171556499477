class token {
	constructor(obj) {
		if (!obj) obj = {};
		this.id = obj.id ? obj.id : undefined;
		this.userName = obj.userName ? obj.userName : undefined;
		this.token = obj.token ? obj.token : '';
		this.title = obj.title || '';
		this.firstName = obj.firstName || '';
		this.lastName1 = obj.lastName1 || '';
		this.lastName2 = obj.lastName2 || '';
		this.fullName = obj.fullName || '';
		this.phone = obj.phone || '';
		this.emergencyPhoneNumber = obj.emergencyPhoneNumber || '';
		this.emergencyContact = obj.emergencyContact || '';
		this.dob = obj.dob;
		this.roleId = obj.roleId ? obj.roleId : undefined;
		this.taxId = obj.taxId || '';
		this.tenantId = obj.tenantId ? obj.tenantId : undefined;
		this.isActive = obj.isActive || false;
		this.isDeleted = obj.isDeleted || false;
		this.needChangePassword = obj.userAccess
			? obj.userAccess.isRecoverPassword || obj.userAccess.isTempPassword
			: false;
	}
}

export default token;
