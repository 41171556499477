import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
	{
		addNotification: ['notification'],
		getNotificationsRequest: [],
		getNotificationsRequestSuccess: ['notifications'],
		readNotificationRequest: ['notificationId'],
		readNotificationRequestSuccess: ['notificationId'],
		onNotificationAdded: ['notification'],
		clearNotification: [],
		onNotificationCleared: [],
	},
	{}
);

export const NotificationTypes = Types;
export const NotificationCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
	notification: null,
	notifications: [],
});

export const onNotificationsRequestSuccess = (state, { notifications }) =>
	state.merge({ notifications });
export const onNotificationReadRequestSuccess = (state, { notificationId }) =>
	state.merge({
		notifications: state.notifications
			.asMutable()
			.filter((x) => x.notificationId !== notificationId),
	});
export const onNotificationAdd = (state, { notification }) =>
	state.merge({ notification });
export const onNotificationClear = (state) =>
	state.merge({ notification: null });
//map our actions types to our reducer functions
export const HANDLERS = {
	[Types.GET_NOTIFICATIONS_REQUEST_SUCCESS]: onNotificationsRequestSuccess,
	[Types.READ_NOTIFICATION_REQUEST_SUCCESS]: onNotificationReadRequestSuccess,
	[Types.ON_NOTIFICATION_ADDED]: onNotificationAdd,
	[Types.ON_NOTIFICATION_CLEARED]: onNotificationClear,
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
//export const selectUser = state => state.userReducer.token;
// export const selectIsAuthenticated = state => state.userReducer.user ? true : false;
export const selectNotification = (state) =>
	state.notificationReducer.notification;
export const selectNotifications = (state) =>
	state.notificationReducer.notifications;
