class EntityBase {
	constructor(obj) {

		if (this.constructor === EntityBase) {
			throw new TypeError('Abstract class "EntityBase" cannot be instantiated directly.');
		}

		this.id = obj.id ? obj.id : undefined;
		this.isActive = obj.isActive ? obj.isActive : false;
		this.createdBy = obj.createdBy ? obj.createdBy : undefined;
		this.createdDate = obj.createdDate ? obj.createdDate : new Date();
		this.modifiedBy = obj.modifiedBy ? obj.modifiedBy : undefined;
		this.modifiedDate = obj.modifiedDate ? obj.modifiedDate : undefined;
	}

}

export default EntityBase;