import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { UserCreators, selectToken, selectPermissionsLoaded, selectPermissions, selectSettings, selectIsAuthenticated, 
    selectIsLoading, selectIsLoadingSession, selectChangePasswordMessage, selectError, selectTenantId } from "../redux/UserRedux";

export const mapStateToProps = state => ({
    isLoading: selectIsLoading(state),
    user: selectToken(state),
    permissions: selectPermissions(state),
    settings: selectSettings(state),
    permissionsLoaded: selectPermissionsLoaded(state),
    authError: selectError(state),
    isAuthenticated: selectIsAuthenticated(state),
    isLoadingSession: selectIsLoadingSession(state),
    changePasswordMessage: selectChangePasswordMessage(state),
    tenantId: selectTenantId(state)
});

export const mapDispatchToProps = dispatch => bindActionCreators({
    login: UserCreators.userLoginRequest,
    setSession: UserCreators.setSession,
    getPermissions: UserCreators.permissionsRequest,
    getSettings: UserCreators.settingsRequest,
    changePassword: UserCreators.changePasswordRequest,
    changeTenant: UserCreators.changeTenant
}, dispatch);

export const hocWithAuth = WrappedComponent => {
    const hoc = ({ ...props }) => <WrappedComponent {...props} />;

    hoc.propTypes = {
        /** Authenticate user with provided credencials */
        login: PropTypes.func,
        changePassword: PropTypes.func,
        isLoading: PropTypes.bool,
        isAuthenticated: PropTypes.bool,
        user: PropTypes.object,
        settings: PropTypes.object,
        notification: PropTypes.object,
        permissions: PropTypes.array,
        getPermissions: PropTypes.func,
        changeTenant: PropTypes.func
    };

    return hoc;
};

export default WithAuth =>
    connect(mapStateToProps, mapDispatchToProps)(
        hocWithAuth(WithAuth)
    );