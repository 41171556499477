import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './../redux';
import sagas from 'sagas';


import { injectStore } from 'services/Api';

// console.log("stores imports");
// console.log("createStore", createStore);
// console.log("applyMiddleware", applyMiddleware);
// console.log("createSagaMiddleware", createSagaMiddleware);
// console.log("composeWithDevTools", composeWithDevTools);
// console.log("rootReducer", rootReducer);
// console.log("sagas", sagas);

const sagaMiddleware = createSagaMiddleware();

// console.log("sagaMiddleware", sagaMiddleware);

/*eslint-disable */
const logger = store => next => action => {
	if (!window.env.LOG_REDUX)
		return next(action);

	console.log("dispatching", action);
	console.log("next state", store.getState());
	
	return next(action);
}

/*eslint-enable */
const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(logger, sagaMiddleware))
);

injectStore(store);

// console.log("store", store);

sagaMiddleware.run(sagas);

export default store;