import { combineReducers } from 'redux';

import userReducer from './UserRedux';
import commonReducer from './CommonRedux';
import notificationReducer from './NotificationRedux';

const rootReducer = combineReducers({
  userReducer,
  commonReducer,
  notificationReducer,
});

export default rootReducer;
