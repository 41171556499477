import API from './Api';

class BusinessFunctionRolesService extends API {
	getAll = () => {
		return this.API.get(`${this.controller}/all`);
	}

	getAssignedRoles = (businessFunctionId) => {
		return this.API.get(`business-functions/${businessFunctionId}/roles`);
	}

	getAssignedBusinessFunctions = (roleId) => {
		return this.API.get(`roles/${roleId}/business-functions`);
	}

	postBusinessFunctionRoles = (model) => {
		return this.API.post(`${this.controller}/massive`, model);
	}
}

let service = new BusinessFunctionRolesService('api/businessFunctionRoles');

export default service;
