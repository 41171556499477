import { create } from 'apisauce';
import axios from 'axios';
import { ResponseStatus } from '../constants';
import i18n from 'i18next';

// console.log("Api out of definition");
// console.log("create", create);
// console.log("axios", axios);
// console.log("ResponseStatus", ResponseStatus);
// console.log("i18n", i18n);

class Api {
	constructor(controller) {
		this.axios = axios.create({ baseURL: window.env.API_URL });

		this.axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		this.axios.interceptors.request.use(
			function (request) {
				return request;
			},
			function (error) {
				return Promise.reject(error);
			}
		);

		this.API = create({
			axiosInstance: this.axios,
		});

		this.validResponse = (response) => {
			const result =
				response.status !== null && response.status !== ResponseStatus.fail;
			return result;
		};

		this.API.addResponseTransform((response) => {
			if (response.status === 401) {
				delete localStorage.appUser;
				window.tenantId = null;
				window.location = '/auth/login';
			} else if (!this.validResponse(response)) {
				response.ok = false;
				response.problem = '';
			}
		});

		this.API.addRequestTransform((request) => {
			const token = window.token;
			//const state = null;
			if (token && token.token) {
				request.headers[
					'Authorization'
				] = `Bearer ${token.token}`;
			}

			const tenantId = window.tenantId;
			if (tenantId) {
				request.headers[
					'Tenant'
				] = tenantId;
			}

			request.headers[
				'Lang'
			] = i18n.language || 'es';
		});

		this.controller = controller;

		this.getById = (id) => {
			return this.API.get(`${this.controller}/${id}`);
		};

		this.getAll = () => {
			return this.API.get(`${this.controller}`);
		};

		this.getAllSimple = () => {
			return this.API.get(`${this.controller}/all`);
		};

		this.upsert = (model) => {
			return this.API.post(`${this.controller}/upsert`, model);
		};
	}
}

export const injectStore = store => {
	store.subscribe(() => {
		const state = store.getState();

		if (state && state.userReducer.isImpersonating && state.userReducer.tenantId) {
			window.tenantId = state.userReducer.tenantId;
		}
		else {
			window.tenantId = null;
		}
	})
}

export default Api;