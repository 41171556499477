import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
	CommonCreators,
	selectBranches,
	selectAppointmentStatuses,
	selectTenants,
} from '../redux/CommonRedux';

export const mapStateToProps = (state) => ({
	branches: selectBranches(state),
	appointmentStatuses: selectAppointmentStatuses(state),
	tenants: selectTenants(state)
});

export const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getBranches: CommonCreators.getBranches,
			getAppointmentStatuses: CommonCreators.getAppointmentStatuses,
			getTenants: CommonCreators.getTenants
		},
		dispatch
	);

export const hocComponent = (WrappedComponent) => {
	const hoc = class HocUsers extends React.Component {
		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	hoc.propTypes = {
		getBranches: PropTypes.func,
		getAppointmentStatuses: PropTypes.func,
		getTenants: PropTypes.func,
		branches: PropTypes.array,
		appointmentStatuses: PropTypes.array,
		tenants: PropTypes.array
	};

	return hoc;
};

export default (WithCommon) =>
	connect(mapStateToProps, mapDispatchToProps)(hocComponent(WithCommon));
