import { takeLatest, call, put } from 'redux-saga/effects';
import { UserTypes, UserCreators } from '../redux/UserRedux';
import userService from 'services/userService';
import businessFunctionRolesService from 'services/businessFunctionRolesService';
import securityService from 'services/securityService';
import User from 'contract/requests/User';
import Token from 'contract/responses/token';
import { MessageOperation } from '../constants';

export function* userLogin({ credential }) {
	yield put(UserCreators.setLoading(true));
	yield put(UserCreators.userLoginFailure(null));

	const response = yield call(securityService.login, credential);

	if (response.ok && response.data.status === 'Success') {
		const token = new Token({
			...response.data.data,
			userName: credential.userName,
		});
		securityService.storeSession(token);
		yield put(UserCreators.userLoginSuccess(token));
	} else {
		let message = 'Ha sucedido un error inesperado';
		if (response.data && response.data.message) {
			message = response.data.message;
		}

		toastr.error(message);

		yield put(UserCreators.userLoginFailure(message));
	}
	yield put(UserCreators.setLoading(false));
}

export function* getUserCatalog() {
	yield put(UserCreators.setLoading(true));
	yield* getAll(false);
	yield put(UserCreators.setLoading(false));
}

export function* getAll(showLoading) {
	if (showLoading) yield put(UserCreators.setLoading(true));

	const response = yield call(userService.getAll);

	if (response.ok) {
		yield put(UserCreators.userListSuccess(response.data.data));
	} else {
		yield put(UserCreators.userSetMessage(MessageOperation.error));
	}

	if (showLoading) yield put(UserCreators.setLoading(false));
}

export function* getAllDoctors() {
	const response = yield call(userService.search, {
		tipo: 53, //doctor
		limit: 1000,
	});

	if (response.ok) {
		let doctors = response.data.data.map((i) => ({
			value: i.id,
			label: `${i.firstName} ${i.lastName1} ${i.lastName2}`,
		}));
		yield put(UserCreators.doctorsGetSuccess(doctors));
	}
}

export function* getAllPatients() {
	const response = yield call(userService.search, {
		tipo: 55, //patient
		limit: 33000,
	});

	if (response.ok) {
		yield put(UserCreators.patientsGetSuccess(response.data.data));
	}
}

export function* getPermissions() {
	const response = yield call(businessFunctionRolesService.getAll);

	if (response.ok) {
		yield put(UserCreators.onPermissionsRequestSuccess(response.data.data));
	}
	else {
		yield put(UserCreators.userSetMessage(MessageOperation.error));
	}
}

export function* getSettings() {
	const response = yield call(userService.getSettings);

	if (response.ok) {
		yield put(UserCreators.onSettingsRequestSuccess(response.data.data));
	}
}

export function* getById({ id }) {
	yield put(UserCreators.setLoading(true));

	const response = yield call(userService.getById, id);

	if (response.ok) {
		yield put(UserCreators.userGetSuccess(response.data.data));
	} else {
		yield put(UserCreators.userSetMessage(MessageOperation.error));
	}

	yield put(UserCreators.setLoading(false));
}

export function* create({ user }) {
	yield put(UserCreators.setLoading(true));

	const response = yield call(userService.create, new User(user));

	if (response.ok && response.data.status === 'Fail') {
		yield put(UserCreators.userSetMessage(response.data.data.error));
		yield put(UserCreators.setLoading(false));
		return;
	}

	if (response.ok) {
		yield put(
			UserCreators.userCreateSuccess(
				response.data.data,
				MessageOperation.success
			)
		);
	} else {
		yield put(UserCreators.userSetMessage(MessageOperation.error));
	}

	yield put(UserCreators.setLoading(false));
}

export function* update({ user }) {
	yield put(UserCreators.setLoading(true));

	const response = yield call(userService.update, user);

	if (response.ok) {
		yield put(UserCreators.userUpdateSuccess(user, MessageOperation.success));
	} else {
		yield put(UserCreators.userSetMessage(MessageOperation.error));
	}

	yield put(UserCreators.setLoading(false));
}

export function* deleteItem({ id }) {
	yield put(UserCreators.setLoading(true));

	const response = yield call(userService.delete, id);

	if (response.ok) {
		yield put(UserCreators.userDeleteSuccess(id, MessageOperation.success));
	} else {
		yield put(UserCreators.userSetMessage(MessageOperation.error));
	}

	yield put(UserCreators.setLoading(false));
}

export function* changePassword({ password }) {
	yield put(UserCreators.resetChangePasswordResult());

	const response = yield call(userService.updatePassword, password);

	if (response.ok) {
		yield put(UserCreators.changePasswordSuccess());
	} else {
		yield put(UserCreators.changePasswordFailure(MessageOperation.error));
	}
}

export function* setEditId({ id }) {
	yield put(UserCreators.setEditIdSuccess(id));
}

function* userSagas() {
	yield takeLatest(UserTypes.DOCTORS_GET_REQUEST, getAllDoctors);
	yield takeLatest(UserTypes.PATIENTS_GET_REQUEST, getAllPatients);
	yield takeLatest(UserTypes.SET_EDIT_ID_REQUEST, setEditId);
	yield takeLatest(UserTypes.USER_LOGIN_REQUEST, userLogin);
	yield takeLatest(UserTypes.USER_GET_REQUEST, getById);
	yield takeLatest(UserTypes.USER_LIST_REQUEST, getUserCatalog);
	yield takeLatest(UserTypes.USER_CREATE_REQUEST, create);
	yield takeLatest(UserTypes.USER_UPDATE_REQUEST, update);
	yield takeLatest(UserTypes.USER_DELETE_REQUEST, deleteItem);
	yield takeLatest(UserTypes.CHANGE_PASSWORD_REQUEST, changePassword);
	yield takeLatest(UserTypes.PERMISSIONS_REQUEST, getPermissions);
	yield takeLatest(UserTypes.SETTINGS_REQUEST, getSettings);
}

export default userSagas;
