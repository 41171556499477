import API from './Api';

class CommonService extends API {
	getRooms = (branchId) => {
		return this.API.get(`${this.controller}/GetRooms?branchId=${branchId}`);
	};

	upsertRoom = (model) => {
		return this.API.post(`${this.controller}/UpsertRoom`, model);
	};

	deleteRoom = (id) => {
		return this.API.get(`${this.controller}/DeleteRoom?roomId=${id}`);
	};

	getCities = (stateId) => {
		return this.API.get(`${this.controller}/GetCities?stateId=${stateId}`);
	};

	getBranches = () => {
		return this.API.get(`${this.controller}/GetBranches`);
	};

	upsertBrach = (model) => {
		return this.API.post(`${this.controller}/UpsertBranch`, model);
	};

	deleteBranch = (id) => {
		return this.API.get(`${this.controller}/DeleteBranch?branchId=${id}`);
	};

	getStates = () => {
		return this.API.get(`${this.controller}/GetStates`);
	};

	getAppointmentStatuses = () => {
		return fetch(`statuses.json`);
	}

	getSpecialties = () => {
		return this.API.get(`${this.controller}/GetSpecialties`);
	};

	searchSpecialties = (name) => {
		return this.API.get(`${this.controller}/SearchSpecialties?name=${name}`);
	};

	getTenants = () => {
		return this.API.get(`${this.controller}/GetTenants`);
	};

	getPaymentTypes = () => {
		return this.API.get(`${this.controller}/GetPaymentTypes`);
	};

	getTreatmentTypes = () => {
		return this.API.get(`${this.controller}/GetTreatmentTypes`);
	};

	getTreatmentStatuses = () => {
		return this.API.get(`${this.controller}/GetTreatmentStatuses`);
	};

	getUABs = () => {
		return this.API.get(`${this.controller}/GetUABs`);
	};
}

let service = new CommonService('api/Common');

export default service;
