/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Moment from 'moment';
import 'moment/locale/es';

import { Router } from 'react-router-dom';
// import { Router, Route, Switch, Redirect } from 'react-router-dom';
// import AuthLayout from 'layouts/Auth.js';
// import AdminLayout from 'layouts/Admin.js';
import Routes from 'routes/index.jsx';
import { Provider } from 'react-redux';
import store from 'stores';
import './i18n';
import Modal from 'react-modal';
//import * as serviceWorker from './sw';

import 'assets/scss/material-dashboard-pro-react.scss?v=1.9.0';
import 'assets/css/custom.css';

Moment.updateLocale('es-MX');
Modal.setAppElement('#root-modal');

const hist = createBrowserHistory();

window.app_version = process.env.version;

let theme = createTheme({
	palette: {
		primary: {
			light: '#4dabf5',
			main: '#2196f3',
			dark: '#1769aa',
			contrastText: '#fff',
		},
		secondary: {
			light: '#834bff',
			main: '#651fff',
			dark: '#4615b2',
			contrastText: '#000',
		},
	},
});

theme = responsiveFontSizes(theme);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Provider store={store}>
	<Router history={hist}>
		<SnackbarProvider>
			<ThemeProvider theme={theme}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<Routes />
				</MuiPickersUtilsProvider>
			</ThemeProvider>
		</SnackbarProvider>
	</Router>
</Provider>);

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register(process.env.PUBLIC_URL + '/sw.js')
		.then(() => {
			// registration worked
			console.log('SW Registration succeeded');
		});
}