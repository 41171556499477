import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Loading from '../views/Loading';
import PrivateRoute from '../routes/PrivateRoute';
import compose from 'recompose/compose';
import WithAuth from '../hoc/WithAuth';
import { withSnackbar } from 'notistack';
import { WithNotifications } from 'hoc';
import { Roles } from 'constants';

// Views
// import AuthLayout from '../layouts/Auth.js';
// import AdminLayout from '../layouts/Admin.js';
const RequestVaccineLayout = lazy(() => import('../layouts/RequestVaccine.js'));
const AutoBill = lazy(() => import('../layouts/AutoBill'));
const AuthLayout = lazy(() => import('../layouts/Auth.js'));
const AdminLayout = lazy(() => import('../layouts/Admin.js'));
const PacientLayout = lazy(() => import('../layouts/Pacient.js'));
const NotFound = lazy(() => import('../views/NotFound'));
const ActivateAccount = lazy(() => import('../views/ActivateAccount'));
// const DoctorLayout = lazy(() => import('../layouts/Doctor.js'));
// const Home = lazy(() => import('../views/Home'));
// const SearchAppointments = lazy(() => import('../views/SearchAppointments'));
// const Agenda = lazy(() => import('../views/Agenda'));
// const Account = lazy(() => import('../views/Account'));
// const ChangePassword = lazy(() => import('../views/ChangePassword'));
// const SignIn = lazy(() => import('../views/SignIn'));
// const Users = lazy(() => import('../views/Users'));
// const UserForm = lazy(() => import('../views/UserForm'));

const Routes = (props) => {
	const {
		notification,
		enqueueSnackbar,
		clearNotification,
		user,
		isAuthenticated,
		location,
		setSession,
	} = props;

	useEffect(() => {
		if (!localStorage.appUser) {
			setSession(null);
		}

		try {
			const user = JSON.parse(localStorage.appUser);
			setSession(user);
			window.token = user;
		} catch (e) {
			setSession(null);
		}
	}, [setSession]);

	useEffect(() => {
		if (props.notification) {
			enqueueSnackbar(
				notification.message,
				notification.options
			);

			clearNotification();
		}
	}, [notification]);



	return (
		<div>
			{!location.pathname.includes('request-vaccine') && isAuthenticated && user.needChangePassword && !user.requestVaccine && (
				<Redirect to="/common/user-page" />
			)}

			<Suspense style={{ height: '100%' }} fallback={<Loading />}>
				<Switch>
					{
						isAuthenticated && user.roleId == Roles.Patient &&
						<Redirect exact from="/" to="/pacient" />
					}
					<Redirect exact from="/index.html" to="/" />
					<Redirect exact from="index.html" to="/" />
					<Route component={NotFound} path="/not-found" />
					<Route path="/auth" component={AuthLayout} />
					<Route path="/billing" component={AutoBill} />
					<Route path="/request-vaccine" component={RequestVaccineLayout} />
					<Route path="/ActivateUser/:id" component={ActivateAccount} />
					<PrivateRoute path="/common" component={AdminLayout} />
					<PrivateRoute path="/pacient" component={PacientLayout} />
					<Redirect exact from="/" to="/common" />

					{/* <Redirect to="/not-found" /> */}
				</Switch>
			</Suspense>
		</div>
	);
}

Routes.propTypes = {
	setSession: PropTypes.func,
};

//export default Routes;

export default compose(
	WithNotifications,
	withSnackbar,
	WithAuth,
	withRouter,
)(Routes);
