import React, { Component } from 'react';
// import ReactLoading from 'react-loading';
import "./styles.css";

export default class Loading extends Component {
    render() {
        return (
            <div className="flex-container">
                <div className="row">
                    {/* <ReactLoading type="bars" color="gray" /> */}
                    <div className="loader" />
                </div>
            </div>
        );
    }
}