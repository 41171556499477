import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import WithAuth from '../hoc/WithAuth';
import WithCommon from '../hoc/WithCommon';
import WithUsers from '../hoc/WithUsers';
import compose from 'recompose/compose';

import { Roles } from '../constants';

class PrivateRoute extends React.Component {
	componentDidMount = () => {
		this.verifyPermissions();
		this.getData();
	};

	getData = () => {
		const { isAuthenticated } = this.props;

		if (isAuthenticated) {
			if (!this.props.doctors || !this.props.doctors.length) {
				this.props.getDoctors();
			}

			if (!this.props.branches || !this.props.branches.length) {
				this.props.getBranches();
			}

			if (!this.props.tenants || !this.props.tenants.length) {
				if (this.props.user.roleId == Roles.SuperAdmin) {
					this.props.getTenants();
				}
				else {
					console.log("User is not a super admin, roleId:", this.props.user.roleId);
				}
			}
			else if (this.props.user.roleId == Roles.SuperAdmin && this.props.tenants) {
				console.log("Super admin already has tenants", this.props.tenants);
			}
		}
	};

	verifyPermissions = () => {
		if (!this.props.permissionsLoaded) return;

		// const hasPermission = this.props.permissions.find(x => this.props.path == x.url);
		// if (!hasPermission) {
		//     this.props.history.push("/");
		// }
	};

	render() {
		const { isAuthenticated, path, history, ...rest } = this.props;

		if (!isAuthenticated && path !== '/auth/login') {
			history.push('/auth/login');
			return null;
		}

		return (
			<Route
				{...rest}
			/>
		);
	}
}

PrivateRoute.propTypes = {
	isAuthenticated: PropTypes.bool,
	history: PropTypes.object,
	Component: PropTypes.func,
	permissionsLoaded: PropTypes.bool,
	doctors: PropTypes.array,
	branches: PropTypes.array,
	tenants: PropTypes.array,
	getDoctors: PropTypes.func,
	getBranches: PropTypes.func,
	getTenants: PropTypes.func,
};

export default compose(
	WithUsers,
	WithAuth,
	WithCommon,
	withRouter
)(PrivateRoute);
