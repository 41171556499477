import EntityBase from '../EntityBase';

class User extends EntityBase {
  constructor(obj) {
    if (!obj) obj = {};
    super(obj);
    this.userName = obj.userName ? obj.userName : '';
    this.role = obj.role ? obj.role : -1;
    this.isTempPassword = obj.isTempPassword ? obj.isTempPassword : false;
    this.securePasswod = obj.securePassword ? obj.securePassword : '';
    this.roleId = obj.roleId ? obj.roleId : -1;
    this.userTypeId = obj.userTypeId ? obj.userTypeId : -1;
    this.companyLocationId = obj.companyLocationId ? obj.companyLocationId : -1;
    this.email = obj.email ? obj.email : '';
    this.displayName = obj.displayName ? obj.displayName : '';
    this.externalId = obj.externalId ? obj.externalId : '';
    this.mobilePhone = obj.mobilePhone ? obj.mobilePhone : '';
    this.isGroup = obj.isGroup ? obj.isGroup : false;
    this.lastLogon = obj.lastLogon ? obj.lastLogon : undefined;
    this.entityTypeId = obj.entityTypeId ? obj.entityTypeId : 1;
  }
}

export default User;
