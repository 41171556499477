import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions({
    getBranches: [],
    getAppointmentStatuses: [],
    getTenants: [],
    setBranches: ["branches"],
    setAppointmentStatuses: ["appoinmentStatuses"],
    setTenants: ["tenants"],
}, {});

export const CommonTypes = Types;
export const CommonCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
    branches: [],
    appoinmentStatuses: [],
    tenants: [],
    isLoading: false,
});

export const setBranches = (state, { branches }) => state.merge({ branches });
export const setAppointmentStatuses = (state, { appoinmentStatuses }) => state.merge({ appoinmentStatuses });
export const setTenants = (state, { tenants }) => state.merge({ tenants });

//map our actions types to our reducer functions
export const HANDLERS = {
    [CommonTypes.SET_BRANCHES]: setBranches,
    [CommonTypes.SET_APPOINTMENT_STATUSES]: setAppointmentStatuses,
    [CommonTypes.SET_TENANTS]: setTenants,
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectBranches = state => Immutable.asMutable(state.commonReducer.branches, { deep: true });
export const selectAppointmentStatuses = state => Immutable.asMutable(state.commonReducer.appoinmentStatuses, { deep: true });
export const selectTenants = state => Immutable.asMutable(state.commonReducer.tenants, { deep: false });