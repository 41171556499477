import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
	UserCreators,
	selectUsers,
	selectIsLoading,
	selectUser,
	selectDoctors,
	selectPatients,
	selectIsImpersonating,
	selectTenantId
} from '../redux/UserRedux';

export const mapStateToProps = (state) => ({
	users: selectUsers(state),
	doctors: selectDoctors(state),
	patients: selectPatients(state),
	user: selectUser(state),
	isLoading: selectIsLoading(state),
	isImpersonating: selectIsImpersonating(state),
	tenantId: selectTenantId(state),
});

export const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getUsers: UserCreators.userListRequest,
			getDoctors: UserCreators.doctorsGetRequest,
			getPatients: UserCreators.patientsGetRequest,
			getUser: UserCreators.userGetRequest,
			createUser: UserCreators.userCreateRequest,
			updateUser: UserCreators.userUpdateRequest,
			deleteUser: UserCreators.userDeleteRequest
		},
		dispatch
	);

export const hocComponent = (WrappedComponent) => {
	const hoc = class HocUsers extends Component {
		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	hoc.propTypes = {
		/** Get all company categories */
		getUsers: PropTypes.func,
		getDoctors: PropTypes.func,
		getPatients: PropTypes.func,
		createUser: PropTypes.func,
		updateUser: PropTypes.func,
		deleteUser: PropTypes.func,
		users: PropTypes.array,
		isLoading: PropTypes.bool,
	};

	return hoc;
};

export default (WithUsers) =>
	connect(mapStateToProps, mapDispatchToProps)(hocComponent(WithUsers));
